<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
  </div>
</template>

<script setup lang="ts">
const { page, redirect } = useCms()

if (redirect.value) {
  await navigateTo(redirect.value.toUrl, { external: true, redirectCode: 301 })
}

if (!page.value) {
  showError({
    statusCode: 404,
    statusMessage: 'pageNotFound',
  })
}

defineOptions({
  name: 'CmsPageSlug',
})
</script>
