<template>
  <PageContainer
    :data-block-id="id"
    :data-block-type="__typename"
    :default-horizontal-spacing="false"
    :default-vertical-spacing
    :full-width
    :height
    class="relative"
  >
    <template v-if="backgroundImage">
      <PageContainerBackground
        :background-gradient-top="backgroundGradientTop"
        :background-gradient-bottom="backgroundGradientBottom"
        :background-image-modifiers="backgroundImageModifiers"
        :background-image="backgroundImage"
        :background-image-position="backgroundImagePosition"
        :preload="blockPosition === 0"
      />
    </template>

    <PageGrid
      :default-horizontal-spacing
      :vertical-align
      :gap
      :height
      :layout
      :full-width
      :class="{
        container: fullWidth,
        'container-spacing-horizontal': defaultHorizontalSpacing,
      }"
    >
      <template #default="{ colSpacing }">
        <PageGridColumn
          v-for="(column, index) in columns"
          :key="index"
          :horizontal-align
          :class="colSpacing"
          :style="'z-index: ' + (columns.length - index)"
        >
          <component
            :is="dynamicComponent[component.__typename]"
            v-for="component in column"
            :key="component.id"
            :render-above-the-fold="index === 0 && blockPosition === 0"
            v-bind="component"
          />
        </PageGridColumn>
      </template>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import type { CmsBlock } from '@cms/types/cms'
import type { Component } from 'vue'
import { PAGE_GRID_GAP_SIZE, PAGE_GRID_LAYOUT } from '@base/constants/page'
import { PAGE_CONTAINER_HEIGHT } from '@base/constants/page-container'
import type { Image, ImageModifier } from '#gql/default'

interface CmsLayoutBlockProps {
  id: string
  __typename: 'CmsLayoutBlock'
  blockPosition: number
  columns: CmsBlock[][]
  height?: PageContainerHeight
  fullWidth?: boolean
  defaultHorizontalSpacing?: boolean
  defaultVerticalSpacing?: boolean
  verticalAlign?: PageGridVerticalAlign
  horizontalAlign?: PageGridColumnHorizontalAlign
  backgroundImage?: Image
  backgroundImageModifiers?: ImageModifier[]
  backgroundImagePosition?: string
  backgroundGradientTop?: boolean
  backgroundGradientBottom?: boolean
  gap?: {
    default?: PageGridGapSize
    sm?: PageGridGapSize
    md?: PageGridGapSize
    lg?: PageGridGapSize
  }
  layout?: {
    sm?: PageGridLayout
    md?: PageGridLayout
    lg?: PageGridLayout
  }
}

const DEFAULT_GAP = {
  default: PAGE_GRID_GAP_SIZE.SM,
  sm: PAGE_GRID_GAP_SIZE.SM,
  md: PAGE_GRID_GAP_SIZE.SM,
  lg: PAGE_GRID_GAP_SIZE.SM,
} as const

const DEFAULT_LAYOUT = {
  sm: PAGE_GRID_LAYOUT.COLS_1,
  md: PAGE_GRID_LAYOUT.COLS_1,
  lg: PAGE_GRID_LAYOUT.COLS_1,
} as const

const {
  height = PAGE_CONTAINER_HEIGHT.FLEXIBLE,
  fullWidth = true,
  defaultHorizontalSpacing = true,
  defaultVerticalSpacing = true,
  verticalAlign,
  horizontalAlign,
  backgroundImagePosition = 'center',
  backgroundGradientTop = false,
  backgroundGradientBottom = false,
  backgroundImageModifiers,
  backgroundImage,
  gap = DEFAULT_GAP,
  layout = DEFAULT_LAYOUT,
} = defineProps<CmsLayoutBlockProps>()

const cmsBlockComponent = computed(() => {
  const cmsBlockComponents = {
    CmsGalleryCarouselBlock: () =>
      import('@cms/components/cms/block/gallery.vue'),
    CmsGalleryHorizontalBlock: () =>
      import('@cms/components/cms/block/gallery.vue'),
    CmsGalleryVerticalBlock: () =>
      import('@cms/components/cms/block/gallery.vue'),
    CmsImageBlock: () => import('@cms/components/cms/block/image.vue'),
    CmsVideoBlock: () => import('@cms/components/cms/block/video.vue'),
    CmsHtmlBlock: () => import('@cms/components/cms/block/html.vue'),
    CmsMovieListBlock: () => import('@cms/components/cms/block/movie-list.vue'),
    CmsLegacyAccountWidgetBlock: () =>
      import('@cms/components/cms/block/legacy-widget.vue'),
    CmsLegacyShopWidgetBlock: () =>
      import('@cms/components/cms/block/legacy-widget.vue'),
    CmsMoviesCatalogBlock: () => import('@cms/components/cms/block/movies.vue'),
    CmsCinemasCatalogBlock: () =>
      import('@cms/components/cms/block/cinemas.vue'),
    CmsNewsletterWidgetBlock: () =>
      import('@cms/components/cms/block/newsletter.vue'),
    CmsEventimNewsletterWidgetBlock: () =>
      import('@cms/components/cms/block/eventim-newsletter.client.vue'),
    CmsProgramBlock: () => import('@cms/components/cms/block/program.vue'),
    CmsAdSlotBlock: () => import('@cms/components/cms/block/ad-slot.vue'),
    CmsFormBlock: () => import('@cms/components/cms/block/form.vue'),
    CmsDividerBlock: () => import('@cms/components/cms/block/divider.vue'),
    CmsGoogleMapsBlock: () =>
      import('@cms/components/cms/block/google-maps.vue'),
    CmsTableBlock: () => import('@cms/components/cms/block/table.vue'),
  } as const

  return Object.fromEntries(
    Object.entries(cmsBlockComponents).map(([type, loader]) => [
      type,
      defineAsyncComponent(loader as () => Promise<{ default: Component }>),
    ])
  ) as Record<CmsBlock, Component>
})

const dynamicComponent = cmsBlockComponent

defineOptions({
  name: 'CmsBlockLayout',
})
</script>
